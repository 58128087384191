import styled from 'styled-components'
import {
  LayoutCard,
  OrderedList,
  Button,
  PartnerLogos,
  PageContainer,
} from '../components'
import {useNavigation, usePrepopulateStore, useProgress} from '../hooks'
import Image from 'next/image'
import {useMediaQuery} from 'react-responsive'
import {MdKeyboardArrowDown} from 'react-icons/md'
import {authWrapper} from '../helpers/authWrapper'
import {useSyncAgentWithAuth0} from '../hooks/useSyncAgentWithAuth0'

const Welcome = (): JSX.Element => {
  const navigate = useNavigation()
  const {incrementProgress} = useProgress()
  const isTabletOrDesktop = useMediaQuery({query: '(min-width: 768px)'})

  // prepopulate the store with mocked data for test environment
  usePrepopulateStore()

  // sync agents table with user info from Auth0
  useSyncAgentWithAuth0()

  return (
    <PageContainer>
      <LayoutCard buttonText="Let's get started" hideFooter fixedHeight={false}>
        <PolicyIcon>
          <Image
            src="/icons/policy-icon.svg"
            alt="Policy icon"
            width={isTabletOrDesktop ? 150 : 113}
            height={isTabletOrDesktop ? 101 : 76}
          />
        </PolicyIcon>
        <h1>We Issue Your Policy Instantly</h1>
        <h2 className="h3">Start To Finish In 4 Minutes</h2>
        <OrderedList
          items={[
            'Answer 8 Questions.',
            'Select Your Payout & Premium.',
            'Provide Personal & Payment Info.',
            "That's It. You're Insured.",
          ]}
        />
        <GetStartedButton
          onClick={(e) => {
            incrementProgress()
            navigate(e, 'gender')
          }}
        >
          Get Started
        </GetStartedButton>
        <DownArrow>
          <MdKeyboardArrowDown />
        </DownArrow>
      </LayoutCard>
      <PartnerLogos />
    </PageContainer>
  )
}

export default authWrapper(Welcome)

const PolicyIcon = styled.div`
  margin-bottom: 21px;

  @media (min-width: 992px) {
    margin-bottom: 35px;
  }
`

const GetStartedButton = styled(Button)`
  @media (min-width: 992px) {
    /* margin-bottom: calc(var(--spacing-unit) * 2.5); */
  }
`

const DownArrow = styled.div`
  font-size: 30px;
  color: var(--text-purple);
  margin-bottom: -8px;
  margin-top: var(--spacing-unit);

  @media (min-width: 992px) {
    margin-bottom: 8px;
  }
`
